<template>
  <base-page
    :title="$t('companyManage.customTitle')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.companyConfigFind"
      :defaultParams="defaultParams"
      :columns="columns"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- logo -->
      <template v-slot:logo="{ row }">
        <el-image
          style="width: 100px; height: 35px"
          :src="row.logo.fileHost + '/' + row.logo.objectName"
          fit="contain"
        ></el-image>
      </template>
      <!-- favicon -->
      <template v-slot:favicon="{ row }">
        <el-image
          style="width: 35px; height: 35px"
          :src="row.favicon.fileHost + '/' + row.favicon.objectName"
          fit="contain"
        ></el-image>
      </template>
    </table-list>
    <!-- 添加私有化企业 -->
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 8,
          tagProps: {
            placeholder: this.$t('companyManage.syNameOrNotes'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      defaultParams: {},
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
          callback(row) {
            return row.company.name
          },
        },
        // lang:logo
        {
          label: 'logo',
          prop: 'logo',
        },
        // lang:ico
        {
          label: 'ico',
          prop: 'favicon',
        },
        // lang:欢迎语
        {
          label: this.$t('companyManage.welcomeWord'),
          prop: 'welcomeWord',
        },
        // lang:联系我们文本
        {
          label: this.$t('companyManage.contactWord'),
          prop: 'contactWord',
        },
        // lang:联系我们链接
        {
          label: this.$t('companyManage.contactLink'),
          prop: 'contactLink',
        },
        // lang:版权信息
        {
          label: this.$t('companyManage.copyright'),
          prop: 'copyright',
        },
      ],
      // 表单页
      showDetail: false,
    }
  },
  mounted() {},
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      const tableRow = this.$util.copyData(this.tableRow)
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyConfigDelete,
            data: tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
  },
}
</script>
<template>
  <dialog-form-list
    width="800px"
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {},
      config: [
        // lang:访问地址
        {
          label: this.$t('companyManage.host'),
          prop: 'host',
          rules: {
            noNull: true,
          },
        },
        // lang:企业编号
        {
          label: this.$t('companyManage.code'),
          prop: 'code',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: cmd !== 'add',
          },
        },
        // lang:私有企业
        {
          tag: 'el-select',
          label: this.$t('companyManage.company'),
          prop: 'company',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            filterable: true,
            remote: true,
            loading: false,
            placeholder: this.$t('companyManage.companyPlaceholder'),
            remoteMethod: this.remoteMethod,
            disabled: cmd !== 'add',
          },
        },
        // lang:logo
        {
          tag: '',
          label: 'logo(201*70)',
          prop: 'logo',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            limit: 1,
          },
        },
        // lang:logo
        {
          tag: '',
          label: 'ico(64*64)',
          prop: 'favicon',
          span: 12,
          rules: {
            noNull: true,
          },
          tagProps: {
            limit: 1,
          },
        },
        // lang:欢迎语
        {
          label: this.$t('companyManage.welcomeWord'),
          prop: 'welcomeWord',
          rules: {
            noNull: true,
          },
        },
        // lang:联系我们文本
        {
          label: this.$t('companyManage.contactWord'),
          prop: 'contactWord',
          rules: {
            noNull: true,
          },
        },
        // lang:联系我们链接
        {
          label: this.$t('companyManage.contactLink'),
          prop: 'contactLink',
          rules: {
            noNull: true,
          },
        },
        // lang:版权信息
        {
          label: this.$t('companyManage.copyright'),
          prop: 'copyright',
          rules: {
            noNull: true,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 搜索企业
    remoteMethod(query) {
      if (!query) {
        this.config[2].tagProps.options = []
        return
      }
      this.config[2].tagProps.loading = true
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findFromClient,
        data: {
          beCustom: 'NO',
          searchStr: query,
          level: 'FIVE',
        },
      })
        .then((data) => {
          this.config[2].tagProps.loading = false
          this.config[2].tagProps.options = data.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        })
        .catch(() => {
          this.config[2].tagProps.loading = false
        })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          const tableRow = this.$util.copyData(this.tableRow)
          this.$util.setDefaultValue(this.formData, tableRow)
          // 企业名称
          this.formData.company = tableRow.company.name
          // logo
          this.formData.logo = [tableRow.logo]
          // favicon
          this.formData.favicon = [tableRow.favicon]
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      formData.logo = formData.logo[0]
      formData.favicon = formData.favicon[0]
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      formData.company = {
        id: formData.company,
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyConfigAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      tableRow.company = this.tableRow.company
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyConfigUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>